<template>
    <section id="product">
        <v-container fluid>
            <v-row align="center" justify="center">
                <v-col cols="8">
                    <v-row align="center" justify="center" class="fill-height">
                        <v-col cols="12">
                            <p class="font-weight-bold text-h5">PINA M</p>
                        </v-col>
                        <v-row class="my-10">
                            <v-col cols="12" md="6">
                                <v-img src="@/assets/img/product/pina_m.png" width="300px" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <p class="font-weight-bold text-h6 mb-5">{{ translations[lang].title }}</p>
                                <div v-html="translations[lang].text" />
                            </v-col>
                        </v-row>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<style>
ul {
    list-style-type: square;
}
li {
    margin: 10px 0px;
}
#product {
    background-color: #f4f7f5;
}
</style>

<script>
export default {
    data() {
        return {
            lang: this.$store.state.lang,
            translations: {
                en: {
                    title: 'MEMS INS for defense, space, and civil applications that require a high level of precision from their inertial sensors.',
                    text: `<ul> 
                                <li>Power Consumption: 576 mW</li> 
                                <li>Power Supply: 3.7~32 V</li> 
                                <li>Weight: 86 g</li> 
                                <li>Dimensions: 55 x 45 x 24.5 mm</li> 
                                <li>RS422 and CAN Interface</li> 
                                <li>Operational Frequency: 200 Hz</li> 
                                <li>Assisted by GPS</li> 
                                <li>ITAR Free</li> 
                                <li>PED (Defense Strategic Product)</li> 
                                <li>National Origin</li> 
                           </ul>`,
                    },
                pt: {
                    title: 'INS de MEMS para aplicações de defesa, espaço e civil que podem prescindir de um alto nível de precisão de seus sensores inerciais.',
                    text: `<ul> 
                                <li>Consumo: 576 mW</li> 
                                <li>Alimentação: 3.7~32 V</li> 
                                <li>Peso: 86 g</li>
                                <li>Dimensões: 55 x 45 x 24.5 mm</li> 
                                <li>Interface RS422 e CAN</li>
                                <li>F. Operacional: 200 Hz</li>
                                <li>Auxiliada por GPS</li>
                                <li>ITAR Free</li>
                                <li>PED</li>
                                <li>Nacional</li>
                            </ul>`,
                },
            }
        };
    },
    created() {
        window.scrollTo(0, 0);
    },
    watch: {
        '$store.state.lang': {
            handler(newValue, oldValue) {
                this.lang = newValue;
            },
        }
    },
};
</script>
