<template>
    <section id="hero">
        <v-parallax src="@/assets/bg/hero.jpg" height="750">
            <v-row align="center" justify="center" class="fill-height">
                <v-col cols="10">
                    <v-row style="margin-top: -150px;">
                        <v-col cols="12" md="6" xl="6" class="hidden-sm-and-down"> </v-col>
                        <v-col cols="12" md="6" xl="6">
                            <p class="font-weight-bold mb-4 text-h3 text-white">{{ translations[lang].text1 }}</p>
                            <p class="font-weight-light text-h4 text-white">{{ translations[lang].text2 }}</p>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-parallax>
    </section>
</template>

<script>
export default {
    data() {
        return {
            lang: this.$store.state.lang,
            translations: {
                en: {
                    text1: 'We innovate to protect, we explore to advance.',
                    text2: 'We are a strategic defense company.'
                },
                pt: {
                    text1: 'Inovamos para proteger, exploramos para avançar.',
                    text2: 'Somos uma empresa estratégica de defesa.'
                },
            }
        };
    },
    watch: {
        '$store.state.lang': {
            handler(newValue, oldValue) {
                this.lang = newValue;
            },
        }
    },
};
</script>