<template>
    <section id="products">
        <v-container fluid class="mt-2">
            <v-row align="center" justify="center">
                <v-col cols="10">
                    <v-row>
                        <v-col cols="12">
                            <p class="font-weight-light text-h5 text-white">{{ translations[lang].titleSec }}</p>
                        </v-col>
                        <v-col v-for="i in 4" :key="i" cols="12" md="3" class="text-center">
                            <v-hover v-slot="{ isHovering, props }">
                                <v-card class="card3" :class="{ 'on-hover': isHovering }" :elevation="isHovering ? 12 : 2" v-bind="props" :href="translations[lang].link[i-1]">
                                    <v-card-item>
                                        <v-img :src="require(`@/assets/img/product/${translations[lang].img[i-1]}`)" max-width="100px" min-height="100px" class="mx-auto" />
                                        <v-card-title>{{ translations[lang].title[i-1] }}</v-card-title>
                                        <v-card-text>{{ translations[lang].text[i-1] }}</v-card-text>
                                    </v-card-item>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<style>
#products {
    background-image: url("~@/assets/bg/vls.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}
.card3 {
    min-height: 300px;
    transition: opacity .4s ease-in-out;
}
.card3:not(.on-hover) {
    opacity: 0.8;
}
</style>

<script>
export default {
    data() {
        return {
            lang: this.$store.state.lang,
            translations: {
                en: {
                    titleSec: 'Products',
                    img:   ['guapara.png', 'panda.png', 'pina_f.png', 'pina_m.png'],
                    link:  ['/guapara', '/panda', '/pina_f', '/pina_m'],
                    title: ['Guapará', 'PANDA', 'PINA-F', 'PINA-M'],
                    text:  ['Remotely Controlled Weapon System (RCWS). Allows remote control of a machine gun through its camera system.',
                            'Portable acquisition of analog data with high precision, ideal for measurements of tactical-grade accelerometers.',
                            'Tactical-grade Inertial Navigation System (INS) for defense, space, and oil applications requiring a high level of precision from their inertial sensors.',
                            'MEMS-based Inertial Navigation System (INS) for defense, space, and civilian applications that can tolerate a lower level of precision from their inertial sensors.']
                },
                pt: {
                    titleSec: 'Produtos',
                    img:   ['guapara.png', 'panda.png', 'pina_f.png', 'pina_m.png'],
                    link:  ['/guapara', '/panda', '/pina_f', '/pina_m'],
                    title: ['Guapará', 'PANDA', 'PINA-F', 'PINA-M'],
                    text:  ['Sistema de Armas Controlado Remotamente (RCWS). Permite controlar uma metralhadora de maneira remota através de seu sistema de câmeras.',
                            'Aquisição portátil de dados analógicos com alta precisão, ideal para medições de acelerômetros de classe tática.',
                            'INS de classe tática para aplicações de defesa, espaço e petróleo que requerem um alto nível de precisão de seus sensores inerciais.',
                            'INS de MEMS para aplicações de defesa, espaço e civil que podem prescindir de um alto nível de precisão de seus sensores inerciais.']
                },
            }
        };
    },
    watch: {
        '$store.state.lang': {
            handler(newValue, oldValue) {
                this.lang = newValue;
            },
        }
    },
};
</script>
