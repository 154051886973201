<template>
    <section id="clients">
        <v-container fluid class="mt-2">
            <v-row align="center" justify="center">
                <v-col cols="10">
                    <v-row>
                        <v-col cols="12">
                            <p class="font-weight-light text-h5">{{ translations[lang].titleSec }}</p>
                        </v-col>
                        <v-slide-group show-arrows class="mt-n10">
                            <v-slide-group-item  v-for="(logo, i) in logos" :key="i" >
                                <a :href="logo.link" target="_blank">
                                    <v-img :src="logo.img" class="mx-2" height="250" width="300" />
                                </a>
                            </v-slide-group-item>
                        </v-slide-group>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    data() {
        return {
            lang: this.$store.state.lang,
            logos: [
                {  img: require('@/assets/img/client/aeb.png'),       link: 'https://www.gov.br/aeb/' },
                {  img: require("@/assets/img/client/albatross.png"), link: 'https://br.linkedin.com/company/albatross-engenharia-ltda' },
                {  img: require("@/assets/img/client/avibras.png"),   link: 'https://www.avibras.com.br' },
                {  img: require("@/assets/img/client/gespi.png"),     link: 'https://www.gespi.com.br' },
                {  img: require("@/assets/img/client/orbital.png"),   link: 'https://orbitalengenharia.com.br' },
                {  img: require("@/assets/img/client/siatt.png"),     link: 'https://www.siatt.com.br' },
                {  img: require("@/assets/img/client/mectron.png"),   link: 'https://pt.wikipedia.org/wiki/Mectron' },
            ],
            translations: {
                en: {
                    titleSec: 'Clients',
                },
                pt: {
                    titleSec: 'Clientes',
                },
            }
        };
    },
    watch: {
        '$store.state.lang': {
            handler(newValue, oldValue) {
                this.lang = newValue;
            },
        }
    },
};
</script>