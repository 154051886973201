<template>
    <div>
        <v-navigation-drawer v-model="drawer" temporary>
            <a href="#hero">
                <v-img alt="Logo" src="@/assets/img/logo.svg" class="ma-5" />
            </a>
            <v-divider />
            <v-list nav>
                <v-list-item v-for="(link, index) in menuLinks" :key="index" :href="link.value" :prepend-icon="link.icon" :title="link.title" class="menu-btn" />
            </v-list>
            <v-divider />
            <v-row align="center" justify="center" class="mt-4">
                <v-btn v-if="this.$store.state.lang === 'en'" @click="handleChangeLang('pt')" :ripple="false" class="flag-btn" size="small" variant="compact">
                    <v-img src="@/assets/icon/flag_br.png" width="32px" />
                    <v-tooltip activator="parent" location="bottom">Versão em Português</v-tooltip>
                </v-btn >
                <v-btn v-else @click="handleChangeLang('en')" :ripple="false" class="flag-btn" size="small" variant="compact">
                    <v-img src="@/assets/icon/flag_usa.png" width="32px" />
                    <v-tooltip activator="parent" location="bottom">English Version</v-tooltip>
                </v-btn>
            </v-row>
        </v-navigation-drawer>

        <v-app-bar class="px-15" height="80">
            <a href="/#hero">
                <v-img alt="Logo" src="@/assets/img/logo.svg" width="160" />
                <v-tooltip activator="parent" location="bottom">Home</v-tooltip>
            </a>
            <v-app-bar-title v-if="!isXs" class="title text-no-wrap">Castro Leite Consultoria</v-app-bar-title>
            <v-spacer />
            <v-app-bar-nav-icon v-if="isXs" @click.stop="drawer = !drawer" />
            <div v-else>
                <v-btn class="menu-btn" href="/#about" size="small" text>{{ translations[lang].aboutUs }}</v-btn>
                <v-btn class="menu-btn" href="/#services" size="small" text>{{ translations[lang].services }}</v-btn>
                <v-btn append-icon="mdi-arrow-down-drop-circle" class="menu-btn" href="/#products" id="menu-activator" size="small" text>{{ translations[lang].products }}</v-btn>
                <v-menu activator="#menu-activator" open-on-hover>
                    <v-list>
                        <v-list-item
                        v-for="(product, index) in products"
                        :key="index"
                        :to=product.link
                        class="menu-btn"
                        >
                        <v-list-item-title>{{ product.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn class="menu-btn" href="/#partners" size="small" text>{{ translations[lang].partners }}</v-btn>
                <v-btn class="menu-btn" href="/#clients" size="small" text>{{ translations[lang].clients }}</v-btn>
                <v-btn class="menu-btn" href="/#contact" size="small" text>{{ translations[lang].contact }}</v-btn>
                <v-btn v-if="this.$store.state.lang === 'en'" @click="handleChangeLang('pt')" :ripple="false" class="flag-btn" size="small">
                    <v-img src="@/assets/icon/flag_br.png" width="32px" />
                    <v-tooltip activator="parent" location="bottom">Versão em Português</v-tooltip>
                </v-btn >
                <v-btn v-else @click="handleChangeLang('en')" :ripple="false" class="flag-btn" size="small">
                    <v-img src="@/assets/icon/flag_usa.png" width="32px" />
                    <v-tooltip activator="parent" location="bottom">English Version</v-tooltip>
                </v-btn>
            </div>
        </v-app-bar>
    </div>
</template>

<style>
.title {
    font-family: 'Gill Sans', 'Gill Sans MT' !important;
    font-weight: bold;
    font-size: 28px;
    flex-grow: 10;
    color: var(--text-reverse);
    text-overflow: show;
}
.flag-btn {
    background-color: transparent !important;
}
.flag-btn:hover { background-color: transparent !important; }
.menu-btn {
    background-color: transparent; /* Cor de fundo transparente */
    color: black;                /* Cor do texto preto */
    margin-right: 1px;
}
.menu-btn:hover {
    background-color: black;     /* Cor de fundo preta ao passar o mouse */
    color: white;                /* Cor do texto branca ao passar o mouse */
}
</style>

<script>
export default {
    data () {
        return {
            drawer: null,
            isXs: false,
            lang: this.$store.state.lang,
            products: [
                { title: 'Guapará', link: 'guapara' },
                { title: 'PANDA',   link: 'panda'   },
                { title: 'PINA-F',  link: 'pina_f'  },
                { title: 'PINA-M',  link: 'pina_m' },
            ],
            translations: {
                en: {
                    aboutUs: 'About Us',
                    services: 'Services',
                    products: 'Products',
                    partners: 'Partners',
                    clients: 'Clients',
                    contact: 'Contact'
                },
                pt: {
                    aboutUs: 'Sobre Nós',
                    services: 'Serviços',
                    products: 'Produtos',
                    partners: 'Parceiros',
                    clients: 'Clientes',
                    contact: 'Contato'
                },
            }
        };
    },
    computed: {
        menuLinks() {
            return [
                { icon: "mdi-office-building", title: this.translations[this.lang].aboutUs,  value: "/#about" },
                { icon: "mdi-toolbox-outline", title: this.translations[this.lang].services, value: "/#services" },
                { icon: "mdi-gift-outline",    title: this.translations[this.lang].products, value: "/#products" },
                { icon: "mdi-handshake",       title: this.translations[this.lang].partners, value: "/#partners" },
                { icon: "mdi-account-group",   title: this.translations[this.lang].clients,  value: "/#clients" },
                { icon: "mdi-email-outline",   title: this.translations[this.lang].contact,  value: "/#contact" }
            ];
        }
    },
    methods: {
        handleChangeLang(newLang) {
            this.$store.commit('changeLang', newLang);
        },
        onResize() {
            this.isXs = window.innerWidth < 960;
        },
    },
    watch: {
        isXs(value) {
            if (!value) {
                if (this.drawer) {
                    this.drawer = false;
                }
            }
        },
        '$store.state.lang': {
            handler(newValue, oldValue) {
                this.lang = newValue;
            },
        }
    },
    mounted() {
        this.onResize();
        window.addEventListener("resize", this.onResize, { passive: true });
    },
};
</script>
