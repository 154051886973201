<template>
    <section id="services">
        <v-container fluid class="mt-2">
            <v-row align="center" justify="center">
                <v-col cols="10">
                    <v-row>
                        <v-col cols="12">
                            <p class="font-weight-light text-h5">{{ translations[lang].titleSec }}</p>
                        </v-col>
                        <v-col v-for="i in 4" :key="i" cols="12" md="3" class="text-center">
                            <v-card class="card2" variant="flat">
                                <v-img :src="require(`@/assets/icon/${translations[lang].img[i-1]}`)" class="mx-auto my-2" height="100px" max-width="100px" />
                                <h1 class="font-weight-regular mb-2">{{ translations[lang].title[i-1] }}</h1>
                                <h4 class="font-weight-regular mx-2">{{ translations[lang].text[i-1] }}</h4>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<style>
.card2 {
    min-height: 100px;
}
</style>

<script>
export default {
    data() {
        return {
            lang: this.$store.state.lang,
            translations: {
                en: {
                    titleSec: 'Services',
                    img:   ['pcb.svg', 'software.svg', 'simulation.svg', 'lab.svg'],
                    title: ['HW', 'SW', 'GNC', 'Lab'],
                    text:  ['We design electronic hardware that meets the standards of MIL-STD-810H.',
                            'We develop embedded software without the use of paid platforms or operating systems.',
                            'We model dynamic systems, as well as define control and guidance laws for missiles, ammunition, and spacecraft.',
                            'Extensive experience in specifying, structuring, and building laboratories for hybrid simulation and environmental testing.']
                },
                pt: {
                    titleSec: 'Serviços',
                    img:   ['pcb.svg', 'software.svg', 'simulation.svg', 'lab.svg'],
                    title: ['HW', 'SW', 'GNC', 'Lab'],
                    text:  ['Projetamos hardwares eletrônicos que atendem aos padrões da norma MIL-STD-810H.',
                            'Desenvolvemos softwares embarcados sem o uso de plataformas ou sistemas operacionais pagos.',
                            'Modelamos sistemas dinâmicos, bem como a definição das leis de controle e de guiamento de mísseis, munições e veículos espaciais.',
                            'Ampla experiência na especificação, estruturação e construção de laboratórios para simulação híbrida, e testes ambientais.']
                },
            }
        };
    },
    watch: {
        '$store.state.lang': {
            handler(newValue, oldValue) {
                this.lang = newValue;
            },
        }
    },
};
</script>
