<template>
  <section id="contact">
    <v-container fluid class="mt-2">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row justify="center">
            <v-col cols="12">
              <p class="font-weight-light text-h5">{{ translations[lang].titleSec }}</p>
            </v-col>
            <v-col cols="12" sm="5">
              <p class="font-weight-light my-2"><div v-html="translations[lang].text" /></p>
              <v-btn v-for="(icon, i) in icons" :key="i" class="mx-4" :href="icon.link" :icon="icon.icon"
                target="_blank" />
            </v-col>
            <v-col cols="12" sm="7">
              <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-text-field v-model="name" :rules="rules.nameRules" :label="translations[lang].formName" required />
                <v-text-field v-model="email" :rules="rules.emailRules" label="E-mail" required />
                <v-textarea v-model="textArea" :rules="rules.textAreaRules" :label="translations[lang].formMessage" required />
                <v-btn :disabled="!valid" color="white" :dark="valid" rounded block class="mt-3" @click="submit" :loading="loading">
                  {{ translations[lang].formButton }}
                </v-btn>
              </v-form>
            </v-col>
            <v-divider />
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="snackbar.enabled" timeout="3000" right top :color="snackbar.color">
      {{ snackbar.text }}
      <template v-slot:actions>
        <v-btn variant="text" @click="snackbar.enabled = false">
          {{ translations[lang].formSnackBtn }}
        </v-btn>
      </template>
    </v-snackbar>

  </section>
</template>

<style>
#contact {
  background-color: black;
  color: white;
}
</style>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      icons: [
        { icon: "mdi-linkedin", link: "https://br.linkedin.com/company/clc-castro-leite-consultoria" },
      //{ icon: "mdi-instagram", link: "https://instagram.com/" },
      ],
      lang: this.$store.state.lang,
      loading: false,
      translations: {
        en: {
          titleSec: 'Contact',
          text: `Fill out the form to send us a message.<br /><br />
                 815 Capitão Raul Fagundes St<br />
                 São José dos Campos - SP, 12215-030<br />
                 Brazil<br /><br />
                 Phone: +55 (12) 3341-6608<br /><br />
                 E-mail: contato@castroleite.com.br<br /><br />`,
          formName: 'Name',
          formMessage: 'Message',
          formButton: 'Send',
          formSnackBtn: 'Close',
          formMsgS: 'Message sent successfully',
          formMsgF: 'Error sending message',
          ruleName1: 'The name field is required',
          ruleName2: 'The name must be longer than 6 characters',
          ruleEmail1: 'The email field is mandatory',
          ruleEmail2: 'Email must be valid',
          ruleText1: 'The text field is required',
          ruleText2: 'Minimum 10 characters'
        },
        pt: {
          titleSec: 'Contato',
          text: `Preencha o formulário para nos enviar uma mensagem.<br /><br />
                 Rua Capitão Raul Fagundes, 815<br />
                 São José dos Campos - SP, 12215-030<br />
                 Brasil<br /><br />
                 Fone: +55 (12) 3341-6608<br /><br />
                 E-mail: contato@castroleite.com.br<br /><br />`,
          formName: 'Nome',
          formMessage: 'Mensagem',
          formButton: 'Enviar',
          formSnackBtn: 'Fechar',
          formMsgS: 'Mensagem enviada com sucesso',
          formMsgF: 'Erro ao enviar mensagem',
          ruleName1: 'O campo nome é obrigatório',
          ruleName2: 'O nome precisa ter mais que 6 caracteres',
          ruleEmail1: 'O e-mail é obrigatório',
          ruleEmail2: 'O e-mail precisa ser válido',
          ruleText1: 'O campo mensagem é obrigatório',
          ruleText2: 'Mínimo de 10 caracteres'
        },
      },
      valid: true,
      name: '',
      email: '',
      textArea: '',
      lazy: false,
      snackbar: {
        color: '',
        enabled: false,
        text: ''
      },
    };
  },
  computed: {
    rules() {
      const translations = this.translations[this.lang];
      return {
        nameRules: [
          (v) => !!v || translations.ruleName1,
          (v) => (v && v.length >= 6) || translations.ruleName2
        ],
        emailRules: [
          (v) => !!v || translations.ruleEmail1,
          (v) => /.+@.+\..+/.test(v) || translations.ruleEmail2
        ],
        textAreaRules: [
          (v) => !!v || translations.ruleText1,
          (v) => (v && v.length >= 10) || translations.ruleText2
        ]
      };
    }
  },
  methods: {
    async submit() {

      const data = {
        name: this.name,
        email: this.email,
        message: this.textArea
      };

      this.loading = true;
      //await axios.post('http://localhost:5000/api/send-mail', data)           // Para uso com o Flask
      await axios.post('https://castroleite.com.br/phpmailer/sender.php', data) // Para uso com o PHPMailer
      .then(response => {

        if (response.data.status === 'success') {
          this.snackbar.text    = this.translations[this.lang].formMsgS
          this.snackbar.color   = "success"
          this.snackbar.enabled = true
        } else {
          // A repetição ocorre, pois a depender do servidor é enviado um status 200
          this.snackbar.text    = this.translations[this.lang].formMsgF
          this.snackbar.color   = "red"
          this.snackbar.enabled = true
        }

      }).catch(error => {

        this.snackbar.text    = this.translations[this.lang].formMsgF
        this.snackbar.color   = "red"
        this.snackbar.enabled = true

      })

      this.$refs.form.reset()
      this.loading = false;
    }
  },
  watch: {
    '$store.state.lang': {
      handler(newValue, oldValue) {
        this.lang = newValue;
      },
    }
  },
};
</script>
