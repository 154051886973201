<template>
    <section id="product">
        <v-container fluid>
            <v-row align="center" justify="center">
                <v-col cols="8">
                    <v-row align="center" justify="center" class="fill-height">
                        <v-col cols="12">
                            <p class="font-weight-bold text-h5">Guapará</p>
                        </v-col>
                        <v-row class="my-10">
                            <v-col cols="12" md="6">
                                <v-img src="@/assets/img/product/guapara/conjunto.png" width="300px" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <p class="font-weight-bold text-h6 mb-5"><div v-html="translations[lang].title[0]" /></p>
                                <div v-html="translations[lang].text[0]" />
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="my-10">
                            <v-col cols="12" md="6" class="order-last order-md-first">
                                <p class="font-weight-bold text-h6 mb-5">{{ translations[lang].title[1] }}</p>
                                <div v-html="translations[lang].text[1]" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-img src="@/assets/img/product/guapara.png" width="300px" />
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="my-10">
                            <v-col cols="12" md="6">
                                <v-img src="@/assets/img/product/guapara/modulo.png" width="300px" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <p class="font-weight-bold text-h6 mb-5">{{ translations[lang].title[2] }}</p>
                                <div v-html="translations[lang].text[2]" />
                            </v-col>
                        </v-row>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<style>
ul {
    list-style-type: square;
}
li {
    margin: 10px 0px;
}
#product {
    background-color: #f4f7f5;
}
</style>

<script>
export default {
    data() {
        return {
            lang: this.$store.state.lang,
            translations: {
                en: {
                    title: ['Remotely Controlled Weapon System (RCWS)', 'Guapará', 'Command Module'],
                    text: [`<ul> 
                                <li>Allows its operator to remotely control a 7.62mm or 5.56mm caliber machine gun through its camera system</li> 
                                <li>Developed for military vessels, ensuring target locking through line of sight stability (azimuth and elevation)</li> 
                                <li>Can be customized for use in land vehicles, helicopters, among others</li> 
                            </ul>`,
                           `<ul> 
                                <li>Vertical Coverage: -10º ~ +45º</li> 
                                <li>Horizontal Coverage: -90º ~ +90º</li> 
                                <li>Enables remote control of a 7.62mm or 5.56mm caliber machine gun</li> 
                                <li>Stabilizes movement in 2 axes</li> <li>Customizable camera system, allowing standard cameras, night vision, and thermal vision</li> 
                            </ul>`,
                           `<ul> 
                                <li>Capacitive touch screen display, 10.1 inches</li> 
                                <li>2 operational viewing modes</li> 
                                <li>Mechanical lock for motor activation</li> 
                                <li>Safe and Arm system that prevents accidental trigger activations</li> 
                                <li>Aim through the operator (joystick)</li> 
                                <li>Dimensions: 43 x 25 x 42 cm</li> 
                            </ul>`]
                    },
                pt: {
                    title: ['Sistema de Armas Controlado Remotamente (RCWS - <i>Remotely Controlled Weapon System</i>)', 'Guapará', 'Módulo de Comando'],
                    text: [`<ul>
                                <li>Permite ao seu operador controlar uma metralhadora de calibre 7,62 mm ou 5,56 mm, de maneira remota através de seu sistema de
                                câmeras</li>
                                <li>Foi desenvolvido para embarcações militares, garantindo a trava do alvo através da
                                estabilidade da linha de tiro (azimute e elevação)</li>
                                <li>Pode ser customizado para uso em veículos terrestres, helicópteros entre outros</li>
                            </ul>`,
                            `<ul>
                                <li>Cobertura Vertical: -10º ~ +45º</li>
                                <li>Cobertura Horizontal: -90º ~ +90º</li>
                                <li>Promove controle remoto de uma metralhadora calibres 7,62 mm ou 5,56 mm</li>
                                <li>Estabiliza o movimento em 2 eixos</li>
                                <li>Sistema de câmeras customizável, permitindo câmeras padrão, visão noturna e térmica</li>
                            </ul>`,
                            `<ul>
                                <li>Display touch screen capacitivo, 10.1polegadas</li>
                                <li>2 modos operacionais de visualização</li>
                                <li>Trava mecânica para ativação dos motores</li>
                                <li>Sistema Safe e Arm que previne acionamentos acidentais do gatilho</li>
                                <li>Mira através do operador (joystick)</li>
                                <li>Dimensões: 43 x 25 x 42 cm</li>
                            </ul>`]
                },
            }
        };
    },
    created() {
        window.scrollTo(0, 0);
    },
    watch: {
        '$store.state.lang': {
            handler(newValue, oldValue) {
                this.lang = newValue;
            },
        }
    },
};
</script>
