<template>
  <section id="footer">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row justify="center">
            <v-col cols="12" align="center" justify="center">
              <p class="font-weight-light"><a class="privacy-link" href="#" @click.prevent="openDialog">{{ translations[lang].privacyLink }}</a></p>
              <p class="font-weight-light">&copy; {{ new Date().getFullYear() }} Castro Leite
                Consultoria. {{ translations[lang].copyright }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
  <v-dialog v-model="dialog" max-width="800">
    <v-card>
      <template v-slot:text>
        <div v-html="translations[lang].privacyText" />
      </template>
      <v-card-actions>
        <v-spacer />
        <v-btn class="privacy-btn" color="white" :text="translations[lang].privacyBtn" @click="dialog = false" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
h1 {
  text-align: center;
}
#footer {
  background-color: black;
  color: white;
}
.privacy-link {
  color: inherit; 
  text-decoration: none;
}
.privacy-btn {
  background-color: black;
}
</style>

<script>
export default {
  data() {
    return {
      dialog: false,
      lang: this.$store.state.lang,
      translations: {
        en: {
          copyright: 'All rights reserved.',
          privacyLink: 'Privacy Policy',
          privacyBtn: 'Close',
          privacyText: `<h1 class="mb-10">Privacy Policy</h1> 
                        <p class="font-weight-light mb-4">This Privacy Policy is intended to inform visitors about the privacy practices of <strong>CASTRO LEITE CONSULTORIA's website</strong>.</p> 
                        <h2 class="mb-4">1. Information We Collect</h2> 
                        <p class="font-weight-light mb-4">Our website does not collect personal information from visitors, such as names, email addresses, phone numbers, or any other personal data.</p> 
                        <h2 class="mb-4">2. Cookies</h2> 
                        <p class="font-weight-light mb-4">Our website may use cookies or similar technologies to enhance visitors' browsing experience. However, these cookies do not contain personal information and are only used for traffic analysis and site functionality purposes.</p> 
                        <h2 class="mb-4">3. Sharing of Information</h2> 
                        <p class="font-weight-light mb-4">We do not share visitors' personal information with third parties.</p> 
                        <h2 class="mb-4">4. Links to Third-Party Sites</h2> 
                        <p class="font-weight-light mb-4">Our website may contain links to third-party sites. However, we are not responsible for the privacy practices or content of these sites. We recommend that visitors review the privacy policies of these sites before providing any personal information.</p> 
                        <h2 class="mb-4">5. Information Security</h2> 
                        <p class="font-weight-light mb-4">We have implemented security measures to protect against the loss, misuse, or alteration of information under our control. However, since we do not collect personal information, there are no personal information to be protected.</p> 
                        <h2 class="mb-4">6. Consent</h2> 
                        <p class="font-weight-light mb-4">By accessing and using our website, visitors agree to this Privacy Policy and acknowledge that we do not collect personal information.</p> 
                        <h2 class="mb-4">7. Changes to the Privacy Policy</h2> 
                        <p class="font-weight-light mb-4">We may update this Privacy Policy periodically to reflect changes in our privacy practices. Any changes will be posted on this page.</p> 
                        <h2 class="mb-4">8. Contact</h2> 
                        <p class="font-weight-light mb-4">If you have any questions or concerns about this Privacy Policy, please contact us at <strong>contact@castroleite.com.br</strong></p> 
                        <p class="font-weight-light">This Privacy Policy was last updated on <strong>March 21, 2024</strong>.</p>`
        },
        pt: {
          copyright: 'Todos os direitos reservados.',
          privacyBtn: 'Fechar',
          privacyLink: 'Política de Privacidade',
          privacyBtn: 'Fechar',
          privacyText: `<h1 class="mb-10" >Política de Privacidade</h1>
                        <p class="font-weight-light mb-4">Esta Política de Privacidade destina-se a informar os visitantes sobre as práticas de privacidade do site da <strong>CASTRO LEITE CONSULTORIA</strong>.</p>
                        <h2 class="mb-4">1. Informações que Coletamos</h2>
                        <p class="font-weight-light mb-4">Nosso site não coleta informações pessoais dos visitantes, como nomes, endereços de e-mail, números de telefone ou quaisquer outros dados pessoais.</p>
                        <h2 class="mb-4">2. Cookies</h2>
                        <p class="font-weight-light mb-4">Nosso site pode utilizar cookies ou tecnologias semelhantes para melhorar a experiência de navegação dos visitantes. No entanto, esses cookies não contêm informações pessoais e são usados apenas para fins de análise de tráfego e funcionalidade do site.</p>
                        <h2 class="mb-4">3. Compartilhamento de Informações</h2>
                        <p class="font-weight-light mb-4">Não compartilhamos informações pessoais dos visitantes com terceiros.</p>
                        <h2 class="mb-4">4. Links para Sites de Terceiros</h2>
                        <p class="font-weight-light mb-4">Nosso site pode conter links para sites de terceiros. No entanto, não somos responsáveis pelas práticas de privacidade ou pelo conteúdo desses sites. Recomendamos que os visitantes revisem as políticas de privacidade desses sites antes de fornecerem qualquer informação pessoal.</p>
                        <h2 class="mb-4">5. Segurança da Informação</h2>
                        <p class="font-weight-light mb-4">Implementamos medidas de segurança para proteger contra a perda, mau uso ou alteração das informações sob nosso controle. No entanto, como não coletamos informações pessoais, não há informações pessoais a serem protegidas.</p>
                        <h2 class="mb-4">6. Consentimento</h2>
                        <p class="font-weight-light mb-4">Ao acessar e utilizar nosso site, os visitantes concordam com esta Política de Privacidade e reconhecem que não coletamos informações pessoais.</p>
                        <h2 class="mb-4">7. Alterações na Política de Privacidade</h2>
                        <p class="font-weight-light mb-4">Podemos atualizar esta Política de Privacidade periodicamente para refletir mudanças em nossas práticas de privacidade. Quaisquer alterações serão publicadas nesta página.</p>
                        <h2 class="mb-4">8. Contato</h2>
                        <p class="font-weight-light mb-4">Se você tiver alguma dúvida ou preocupação sobre esta Política de Privacidade, entre em contato conosco em <strong>contato@castroleite.com.br</strong></p>
                        <p class="font-weight-light">Esta Política de Privacidade foi atualizada pela última vez em <strong>21/03/2024</strong>.</p>`
        },
      },
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    }
  },
  watch: {
    '$store.state.lang': {
      handler(newValue, oldValue) {
        this.lang = newValue;
      },
    }
  },
};
</script>
