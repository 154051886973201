<template>
    <v-container fluid class="mt-2">
        <v-row align="center" justify="center">
            <v-col cols="10">
                <v-row>
                    <v-col v-for="i in 3" :key="i" cols="12" md="4" class="text-center">
                        <v-card class="card">
                            <v-img :src="require(`@/assets/icon/${translations[lang].img[i-1]}`)" class="mx-auto my-2" max-width="100px" />
                            <h1 class="font-weight-regular mb-2">{{ translations[lang].title[i-1] }}</h1>
                            <h4 class="font-weight-regular mx-2">{{ translations[lang].text[i-1] }}</h4>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<style>
.card {
    min-height: 300px;
}
</style>

<script>
export default {
    data() {
        return {
            lang: this.$store.state.lang,
            translations: {
                en: {
                    img:   ['rocket.png', 'defense.png', 'compass.png'],
                    title: ['Aerospace', 'Defense', 'Inertial Systems'],
                    text:  ['We design and develop Guidance, Navigation, and Control (GNC) systems for aerospace vehicles.',
                            'We have the capability and experience in Dynamic Systems Modeling, as well as the Definition of Control and Guidance Laws for missiles.',
                            'We are specialists in the field of inertial navigation, possessing the only nationally qualified platform (inertial aided by GNSS) in flight.']
                },
                pt: {
                    img:   ['rocket.png', 'defense.png', 'compass.png'],
                    title: ['Aeroespacial', 'Defesa', 'Sistemas Inerciais'],
                    text:  ['Projetamos e desenvolvemos sistemas de Guiamento, Navegação e Controle (GNC) para veículos aeroespaciais.',
                            'Capacidade e experiência no Modelamento de Sistemas Dinâmicos, bem como a Definição das Leis de Controle e de Guiamento de mísseis.',
                            'Somos especialistas na área de navegação inercial possuindo a única plataforma nacional (inercial com auxilio de GNSS) qualificada em voo.']
                },
            }
        };
    },
    watch: {
        '$store.state.lang': {
            handler(newValue, oldValue) {
                this.lang = newValue;
            },
        }
    },
};
</script>
