import { createStore } from 'vuex'

export default createStore({
  state: {
    lang: 'pt'
  },
  getters: {
  },
  mutations: {
    changeLang(state, newLang) {state.lang = newLang;},
  },
  actions: {
  },
  modules: {
  }
})
