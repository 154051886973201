<template>
    <section id="about">
        <v-container fluid class="mt-2">
            <v-row align="center" justify="center">
                <v-col cols="10">
                    <v-row align="center" justify="center" class="mb-2">
                        <v-col cols="12" md="7">
                            <p class="font-weight-light text-h5">{{ translations[lang].titleSec }}</p>
                            <p class="mt-8 mb-4 text-justify"><div v-html="translations[lang].text" /></p>
                            <v-row>
                                <v-col cols="12" class="d-flex align-center">
                                    <v-img class="mr-4" max-width="50px" src="@/assets/icon/rocket.svg" />
                                    <p>{{ translations[lang].text2[0] }}</p>
                                </v-col>
                                <v-col cols="12" class="d-flex align-center">
                                    <v-img class="mr-4" max-width="50px" src="@/assets/icon/brasil.png" />
                                    <p>{{ translations[lang].text2[1] }}</p>
                                </v-col>
                                <v-col cols="12" class="d-flex align-center">
                                    <v-img class="mr-4" max-width="50px" src="@/assets/img/eed.png" />
                                    <p>{{ translations[lang].text2[2] }}</p>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="5" class="d-none d-md-flex">
                            <v-img src="@/assets/img/product/pina_m.png" class="d-block ml-auto mr-auto" max-width="300px" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<style>
#about {
    background-color: #f4f7f5;
}
.custom-list {
    list-style-type: square;
}
.custom-list-item {
    margin: 10px 0px;
    margin-left: 40px;
}
</style>

<script>
export default {
    data() {
        return {
            lang: this.$store.state.lang,
            translations: {
                en: {
                    titleSec: 'About Us',
                    /*text: `Castro Leite Consulting, CLC, has been operating for over 10 years in the defense and space sectors, 
                           providing consulting and development services. We specialize in the area of GNC (Guidance, Navigation, and Control), 
                           with one of its founders serving as the head of control for VLS, the largest Brazilian rocket ever built. 
                           We have a highly qualified team, composed of postgraduate professionals in Engineering and Space Technologies.`*/
                    text: `We provide specialized engineering consulting services in the development of navigation, guidance, control, and dynamic systems simulation`,
                    text2: ['10 years in the market', '100% national', 'Strategic Defense Company']
                },
                pt: {
                    titleSec: 'Sobre Nós',
                    /*text: `A Castro Leite Consultoria, CLC, atua há mais de 10 anos nos segmentos de defesa e
                           espaço, fornecendo serviços de consultoria e desenvolvimento. Somos especialistas na área
                           de GNC (Guiamento, Navegação e Controle), sendo um de seus fundadores o chefe do
                           controle do VLS, o maior foguete brasileiro já construído. Contamos com uma equipe
                           altamente qualificada, composta por profissionais pós-graduados em Engenharia e
                           Tecnologias Espaciais.`*/
                    text: `Atuamos na prestação de serviços de consultoria em engenharia especializados no desenvolvimento de 
                            sistemas de navegação, guiamento, controle e simulação de sistemas dinâmicos.`,
                    text2: ['10 anos no mercado', '100% nacional', 'Empresa Estratégica de Defesa']
                },
            }
        };
    },
    watch: {
        '$store.state.lang': {
            handler(newValue, oldValue) {
                this.lang = newValue;
            },
        }
    },
};
</script>
