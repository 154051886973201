<template>
  <Hero />
  <Features />
  <About />
  <Services />
  <Products />
  <Partners />
  <Clients />
  <Contact />
  <v-scale-transition>
      <v-btn        
        @click="toTop"
        color="black"
        icon="mdi-arrow-up"
        fab
        v-scroll="onScroll"
        v-show="fab"
        size="large"
        style="position: fixed; bottom: 10px; right: 10px;"
      />
    </v-scale-transition>
  <Foote />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import Hero     from '@/components/Hero.vue';
import Features from '@/components/Features.vue';
import About    from '@/components/About.vue';
import Services from '@/components/Services.vue';
import Partners from '@/components/Partners.vue';
import Products from '@/components/Products.vue';
import Clients  from '@/components/Clients.vue';
import Contact  from '@/components/Contact.vue';

export default defineComponent({
  name: 'Home',

  components: {
    Hero,
    Features,
    About,
    Services,
    Products,
    Partners,
    Clients,
    Contact
  },

  data() {
    return {
      fab: false,
      color: "transparent",
      flat: true,
    };
  },

  methods: {
    onScroll() {
      const top = window.pageYOffset || document.documentElement.scrollTop;
      this.fab = top > 60;
      if (this.fab) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
    toTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
});
</script>
