<template>
    <section id="product">
        <v-container fluid>
            <v-row align="center" justify="center">
                <v-col cols="8">
                    <v-row align="center" justify="center" class="fill-height">
                        <v-col cols="12">
                            <p class="font-weight-bold text-h5">PANDA</p>
                        </v-col>
                        <v-row class="my-10">
                            <v-col cols="12" md="6">
                                <v-img src="@/assets/img/product/panda.png" width="300px" />
                            </v-col>
                            <v-col cols="12" md="6">
                                <p class="font-weight-bold text-h6 mb-5">{{ translations[lang].title }}</p>
                                <div v-html="translations[lang].text" />
                            </v-col>
                        </v-row>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<style>
ul {
    list-style-type: square;
}
li {
    margin: 10px 0px;
}
#product {
    background-color: #f4f7f5;
}
</style>

<script>
export default {
    data() {
        return {
            lang: this.$store.state.lang,
            translations: {
                en: {
                    title: 'Portable acquisition of analog data with high precision, ideal for measurements of tactical-grade accelerometers.',
                    text: `<ul> 
                                <li>Data transmission frequency: 125 Hz (8ms)</li> 
                                <li>Precision: 100 microG (<10-4)</li> 
                                <li>Measurement range: ± 5G (± 10 V), which can be customized</li> 
                                <li>Allows accelerometer self-test</li> 
                                <li>± 15V for accelerometer power supply</li> 
                                <li>Dimensions: 124 x 106 x 38 mm</li> 
                                <li>Accelerometer connector and USB output</li> 
                                <li>Power supply: from 19 to 36 V (standard notebook power supply)</li> 
                           </ul>`,
                    },
                pt: {
                    title: 'Aquisição portátil de dados analógicos com alta precisão, ideal para medições de acelerômetros de classe tática.',
                    text: `<ul> 
                                <li>Frequência de transmissão de dados: 125 Hz (8ms)</li> 
                                <li>Precisão: 100microG (<10-4)</li> 
                                <li>Faixa de medição: ± 5G (± 10 V), que pode ser personalizado</li>
                                <li>Permite autoteste do acelerômetro</li> 
                                <li>± 15V para fonte de alimentação de acelerômetro</li>
                                <li>Dimensões: 124 x 106 x 38 mm</li>
                                <li>Conector do acelerômetro e saída USB</li>
                                <li>Fonte de alimentação: de 19 a 36 V (fonte de alimentação padrão para notebook)</li>
                            </ul>`,
                },
            }
        };
    },
    created() {
        window.scrollTo(0, 0);
    },
    watch: {
        '$store.state.lang': {
            handler(newValue, oldValue) {
                this.lang = newValue;
            },
        }
    },
};
</script>
