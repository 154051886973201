<template>
    <section id="partners">
        <v-container fluid class="mt-2">
            <v-row align="center" justify="center">
                <v-col cols="10">
                    <v-row align="center" justify="center" class="fill-height">
                        <v-col cols="12">
                            <p class="font-weight-light text-h5">{{ translations[lang].titleSec }}</p>
                        </v-col>
                        <v-row v-for="i in 2" :key="i" class="mb-2">
                            <v-col cols="12" sm="6">
                                <v-img :src="require(`@/assets/img/${translations[lang].img[i - 1]}`)" height="100px" />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <p>{{ translations[lang].text[i - 1] }}</p>
                            </v-col>
                        </v-row>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<style>
#partners {
    background-color: #f4f7f5;
}
</style>

<script>
export default {
    data() {
        return {
            lang: this.$store.state.lang,
            translations: {
                en: {
                    titleSec: 'Partners',
                    img: ['innalabs.png', 'exail.png'],
                    text: [`Innalabs is a renowned company specialized in inertial sensor technology, 
                            producing accelerometers, gyroscopes, and high-performance systems for 
                            precision guidance, stabilization, navigation, and orientation applications.`,
                           `Exail is a company that manufactures high-performance positioning and speed 
                            tables featuring all the necessary performance parameters for testing and 
                            calibrating inertial navigation systems based on MEMS or FOG or optronic payloads.`]
                },
                pt: {
                    titleSec: 'Parceiros',
                    img: ['innalabs.png', 'exail.png'],
                    text: [`A Innalabs é uma renomada empresa especializada em tecnologia de sensores inerciais,
                             produzindo acelerômetros, giroscópios e sistemas de alto desempenho para
                             aplicações de orientação, estabilização, navegação e orientação de precisão.`,
                            `A Exail é uma empresa que fabrica mesas de posicionamento e
                             velocidade de alto desempenho que apresentam todos os parâmetros de
                             desempenho necessários para teste e calibração de sistemas de navegação inercial
                             baseados em MEMS ou FOG ou cargas úteis optrônicas.`]
                },
            }
        };
    },
    watch: {
        '$store.state.lang': {
            handler(newValue, oldValue) {
                this.lang = newValue;
            },
        }
    },
};
</script>
