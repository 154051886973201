<template>
  <v-app>
    <Navigation />
    <v-main>
      <router-view/>
    </v-main>
    <Foote />
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation";
import Foote      from "./components/Footer";

export default {
  name: 'App',

  components: {
    Navigation,
    Foote
  },

  data: () => ({
    //
  }),
}
</script>
