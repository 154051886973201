import { createRouter, createWebHistory } from 'vue-router'
import Home                               from '@/views/Home.vue'
import Guapara                            from '@/views/Guapara.vue'
import Panda                              from '@/views/Panda.vue'
import PinaF                              from '@/views/PinaF.vue'
import PinaM                              from '@/views/PinaM.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { pageTitle: "Castro Leite Consuloria | CLC" },
  },
  {
    path: '/guapara',
    name: 'guapara',
    component: Guapara,
    meta: { pageTitle: "Guapará | CLC" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/panda',
    name: 'panda',
    component: Panda,
    meta: { pageTitle: "Panda | CLC" },
  },
  {
    path: '/pina_f',
    name: 'pina_f',
    component: PinaF,
    meta: { pageTitle: "PINA F | CLC" },
  },
  {
    path: '/pina_m',
    name: 'pina_m',
    component: PinaM,
    meta: { pageTitle: "PINA M | CLC" },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.document.title =
    to.meta && to.meta.pageTitle
      ? to.meta.pageTitle
      : "Castro Leite Consultoria";
  next();
});

export default router
